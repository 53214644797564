<template>
    <v-col cols="12" class="pr-md-5 py-0">
        <v-row>
            <v-col cols="12" class="pt-0">
                <div class="primary--text"><b>{{ $t('forms.PromotionForm.sections.triggers.title') }}</b></div>
                <div v-if="!isDetails" class="secondary--text mb-2">{{ $t('forms.PromotionForm.sections.triggers.description') }}</div>
            </v-col>
        </v-row>

        <template v-if="firstTrigger">
            <v-row>
                <v-col cols="12" class="py-0">
                    <cp-select-field
                        ref="select_trigger_type_input"
                        :placeholder="$t('forms.PromotionForm.sections.triggers.types.placeholder')"
                        :label="$t('forms.PromotionForm.sections.triggers.types.label')"
                        :hint="$t('forms.PromotionForm.sections.triggers.types.hint')"
                        :error-messages="getErrorMessages(`triggers[0].type`)"
                        :items="availableTriggers"
                        item-value="type"
                        item-text="label"
                        v-model="firstTrigger.type"
                        :clearable="true"
                        @input="clearErrorMessages(`triggers[0].type`)"
                        :disabled="isDetails"
                    />
                </v-col>
            </v-row>

            <component
                :is="firstTrigger.getSettingsComponent()"
                v-model="firstTrigger"
                :getErrorMessages="getErrorMessages"
                :clearErrorMessages="clearErrorMessages"
                :formType="formType"
            />
        </template>

    </v-col>
</template>

<script>
import FormMixin from '@/mixins/FormMixin'
import Promotion from '@/models/Promotion'

export default {
    name: 'TriggerPromotionFieldset',
    mixins: [ FormMixin(Promotion, '') ],
    props: {
        value: Promotion,
        formType: {
            type    : String,
            required: false,
            default : 'CREATE',
        },
    },
    computed: {
        availableTriggers() {
            return [
                { label: this.$t('forms.PromotionForm.sections.triggers.types.click_banner'),   type: this.$constants.getConstant('PROMOTION_TYPE_BANNER_CLICK') },
                { label: this.$t('forms.PromotionForm.sections.triggers.types.internal_link'),  type: this.$constants.getConstant('PROMOTION_TYPE_INTERNAL_LINK') },
                { label: this.$t('forms.PromotionForm.sections.triggers.types.profile_update'), type: this.$constants.getConstant('PROMOTION_TYPE_PROFILE_UPDATE') },
            ]
        },
        firstTrigger() {
            return this.$lodash.get(this, 'value.triggers[0]')
        },
        isDetails() {
            return this.formType == 'DETAILS'
        },
    },
}
</script>

<style>

</style>