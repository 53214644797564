import BaseModel from './BaseModel'

export default class PromotionAction extends BaseModel {
    defaults() {
        return {
            id          : undefined,
            type        : undefined,   // enum: GENERATE_POINTS
            promotion_id: undefined,   // definido automaticamente via parent_link
            config      : {
                products: [],
            },
        }
    }
}