import SharedPromotionFilter from '@/shared/models/PromotionFilter'
import $constants from '@/services/constants'
import $i18n from '@/i18n'
import PromotionFilterTimePastSettings from '@/components/forms/fieldset/promotion/filters_settings/PromotionFilterTimePastSettings'
import PromotionFilterActivationsLimit from '@/components/forms/fieldset/promotion/filters_settings/PromotionFilterActivationsLimit'

export default class PromotionFilter extends SharedPromotionFilter {
    defaults() {
        return {
            ...super.defaults(),
        }
    }

        /**
     * Retorna o componente que configura um gatilho de promoção deste tipo
     *
     * @returns {VueComponent}
     */
    getSettingsComponent() {
        const mapping = {
            [$constants.getConstant('PROMOTION_FILTER_TYPE_NEW_REGISTER')]     : PromotionFilterTimePastSettings,
            [$constants.getConstant('PROMOTION_FILTER_TYPE_ACTIVATIONS_LIMIT')]: PromotionFilterActivationsLimit,
        }
    
        let component = mapping[this.type]
    
        if (!component)
            return
    
        return component
    }

    get label() {
        return ({
            [$constants.getConstant('PROMOTION_FILTER_TYPE_NEW_REGISTER')]     : $i18n.t('forms.PromotionForm.sections.filters.types.new_register'),
            [$constants.getConstant('PROMOTION_FILTER_TYPE_ACTIVATIONS_LIMIT')]: $i18n.t('forms.PromotionForm.sections.filters.types.activations_limit'),
            [$constants.getConstant('PROMOTION_FILTER_TYPE_COMPLETE_PROFILE')] : $i18n.t('forms.PromotionForm.sections.filters.types.complete_profile'),
        })[this.type]
    }

    static getAvailableFilters(selected = []) {
        let allFilters = [
            new PromotionFilter({ type: $constants.getConstant('PROMOTION_FILTER_TYPE_NEW_REGISTER') }),
            new PromotionFilter({ type: $constants.getConstant('PROMOTION_FILTER_TYPE_ACTIVATIONS_LIMIT') }),
            new PromotionFilter({ type: $constants.getConstant('PROMOTION_FILTER_TYPE_COMPLETE_PROFILE') }),
        ]

        return allFilters.filter(filter => !selected.find(f => f.type == filter.type))
    }
}