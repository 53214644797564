import SharedPromotion  from '@/shared/models/Promotion'
import PromotionTrigger from './PromotionTrigger'
import PromotionFilter  from './PromotionFilter'
import PromotionAction  from './PromotionAction'
import moment           from 'moment'
import _                from 'lodash'

export default class Promotion extends SharedPromotion {

    defaults() {
        return {
            ...super.defaults(),
        }
    }

    relationships() {
        return {
            triggers: { class: [ PromotionTrigger ], defaultValue: [] },
            filters : { class: [ PromotionFilter  ], defaultValue: [] },
            actions : { class: [ PromotionAction  ], defaultValue: [] },
        }
    }

    validation() {
        return {
            ...super.validation(),
        }
    }
    removeVoidPromotions(data) {
        const types = ['filters', 'actions', 'triggers']
        for (let index in types) {
            if (!data[types[index]][0].type) {
                data[types[index]] = []
            }
        }
    }

    getData() {
        let data = super.getData(...arguments)

        //  Garante que os dados passados serão do tipo especificado pelo schema
        let timePast     = _.get(data, 'filters[0].config.time_past')
        let productValue = _.get(data, 'actions[0].config.products[0].value')

        if (timePast) {
            _.set(data, 'filters[0].config.time_past', _.toInteger(timePast))
        }

        if (productValue) {
            _.set(data, 'actions[0].config.products[0].value', _.toNumber(productValue))
        }

        // Remove todas relações que não têm tipo definido
        data.triggers = (data.triggers || []).filter(f => !!f.type)
        data.filters  = (data.filters || []).filter(f => !!f.type)
        data.actions  = (data.actions || []).filter(f => !!f.type)

        // Remove atributos das relações que não devem ser enviados
        data.triggers = data.triggers.map(f => _.omit(f, ['id', 'promotion_id']))
        data.filters  = data.filters.map(f => _.omit(f, ['id', 'promotion_id']))
        data.actions  = data.actions.map(f => _.omit(f, ['id', 'promotion_id']))

        return data
    }

    async create() {
        let data = this.getData([
            'name', 'description', 'start_at', 'end_at', 'promo_url',
            'photo_url', 'show_in_modal', 'triggers', 'filters', 'actions'
        ])

        
        let response = await this.request({
            url   : `/promotion`,
            method: 'post',
            data,
        })

        if (!response)
            return

        if (response.data)
            this._fill(response.data)

        return response
    }

    async update() {
        let data = this.getData([
            'name', 'description', 'start_at', 'end_at', 'promo_url',
            'photo_url', 'show_in_modal', 'triggers', 'filters', 'actions'
        ])
        
        let response = await this.request({
            url   : `/promotion/${this.id}`,
            method: 'put',
            data,
        })

        if (!response)
            return

        if (response.data)
            this._fill(response.data)

        return response
    }

    async delete() {
        return await this.request({
            url   : `/promotion/${this.id}`,
            method: 'delete',
        })
    }

    get is_active() {
        let startAt = moment.utc(this.start_at)
        let endAt   = moment.utc(this.end_at)

        return moment().isAfter(startAt) && moment().isBefore(endAt)
    }
}