<template>
    <BaseModal ref="modal"
        @cancel="cancel" @confirm="triggerSubmit"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        persistent
        :max-width="$vuetify.breakpoint.mdAndUp ? '570px' : '330px'"
    >
        <!--
            Não é possível usar o v-if porque, em beforeConfirm, precisamos usar uma função deste
            elemento durante o loading, ou seja, precisamos dele rederizado, mas não visível, mesmo
            enquanto em loading
        -->
        <PromotionForm
            ref="promotion_form"
            v-model="promotion"
            :isFormValid.sync="isFormValid"
            :formType="'CREATE'"
            @submit="confirm"
            @triggerSubmit="triggerSubmit"
            v-show="!loading"
            :loading="loading"
            @close-modal="isModalOpen = false"
        />
        <span v-if="loading">{{ $t('forms.wait') }}</span>
    </BaseModal>
</template>

<script>
import PromotionForm from '@/components/forms/PromotionForm'
import BaseModal from '@/components/modals/BaseModal'
import BaseModalMixin from '../BaseModalMixin'
import Promotion from '@/models/Promotion'

export default {
    name: 'PromotionsCreateModal',
    mixins: [ BaseModalMixin ],
    components: { BaseModal, PromotionForm },
    props: {
        promotion: {
            type: Promotion,
            required: true,
        },
    },
    data: vm => {
        return {
            isFormValid: false,
            title: vm.$t('views.promotions.Create.title'),
            loading: false,
        }
    },
    methods: {
        triggerSubmit() {
            this.$refs.promotion_form.submit()
        },
        async beforeConfirm(promotion) {
            if (!this.isFormValid)
                return false

            this.loading = true

            try {
                await promotion.create()
                await this.$refs.promotion_form.triggerFileUploads()
                await this.$sleep(1000)
            } catch(err) {
                await this.preErrorHandler(err)
                return
            } finally {
                this.loading = false
            }

            this.resolveValue = promotion
            return true
        },
        async preErrorHandler(err) {
            let errType = this.$lodash.get(err, 'response.data.type')
            if (errType) {
                const definedErrors = [
                    'used_trigger_code',
                ]
                errType = definedErrors.includes(errType) ? errType : 'error'
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t(`views.promotions.Create.alerts.${errType}`), 'error', resolve)
                })
            }

            this.errorHandler(err)
        },
    },
    computed: {
        computedConfirmButton() {
            return {
                label: this.$t('actions.save'),
                disabled: !this.isFormValid,
                loading: this.loading,
            }
        },
        computedCancelButton() {
            return {
                disabled: this.loading,
            }
        },
    },
}
</script>