var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip",
    _vm._g(
      _vm._b(
        { attrs: { color: _vm.computedColor } },
        "v-chip",
        _vm.attrs,
        false
      ),
      _vm.listeners
    ),
    [_c("span", { class: _vm.labelClass }, [_vm._v(_vm._s(_vm.computedLabel))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }