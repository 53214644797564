var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "12" } },
        [
          _c("cp-text-field", {
            attrs: {
              label: _vm.$t(
                "forms.PromotionForm.sections.triggers.internal_link.label"
              ),
              placeholder: _vm.$t(
                "forms.PromotionForm.sections.triggers.internal_link.placeholder"
              ),
              hint: _vm.$t(
                "forms.PromotionForm.sections.triggers.internal_link.hint"
              ),
              type: "text",
              "error-messages": _vm.getErrorMessages(
                "triggers[0].config.custom_internal_link_code"
              ),
              disabled: _vm.isDetails
            },
            on: {
              input: function($event) {
                return _vm.clearErrorMessages(
                  "triggers[0].config.custom_internal_link_code"
                )
              }
            },
            model: {
              value: _vm.value.config.custom_internal_link_code,
              callback: function($$v) {
                _vm.$set(_vm.value.config, "custom_internal_link_code", $$v)
              },
              expression: "value.config.custom_internal_link_code"
            }
          })
        ],
        1
      ),
      _c("v-col", [
        _c("p", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.computedCode,
              expression: "computedCode"
            }
          ],
          staticClass: "secondary--text mb-0",
          domProps: {
            innerHTML: _vm._s(
              _vm.$t(
                "forms.PromotionForm.sections.triggers.internal_link.hint_details",
                { link: _vm.computedLink }
              )
            )
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }