var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "12" } },
        [
          _c("cp-text-field", {
            attrs: {
              placeholder: _vm.$t(
                "forms.PromotionForm.sections.actions.generate_points.value.placeholder"
              ),
              label: _vm.$t(
                "forms.PromotionForm.sections.actions.generate_points.value.label"
              ),
              hint: _vm.$t(
                "forms.PromotionForm.sections.actions.generate_points.value.hint"
              ),
              type: "number",
              rules: _vm.gtz,
              "error-messages": _vm.getErrorMessages(
                "actions[0].config.products[0].value"
              ),
              disabled: _vm.isDetails
            },
            on: {
              input: function($event) {
                return _vm.clearErrorMessages(
                  "actions[0].config.products[0].value"
                )
              }
            },
            model: {
              value: _vm.value.config.products[0].value,
              callback: function($$v) {
                _vm.$set(_vm.value.config.products[0], "value", $$v)
              },
              expression: "value.config.products[0].value"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "12" } },
        [
          _c("cp-select-field", {
            attrs: {
              placeholder: _vm.$t(
                "forms.PromotionForm.sections.actions.generate_points.type.placeholder"
              ),
              label: _vm.$t(
                "forms.PromotionForm.sections.actions.generate_points.type.label"
              ),
              hint: _vm.$t(
                "forms.PromotionForm.sections.actions.generate_points.type.hint"
              ),
              "error-messages": _vm.getErrorMessages(
                "actions[0].config.products[0].type_id"
              ),
              "hide-details": "",
              items: _vm.types,
              "item-value": "id",
              "item-text": "name",
              loading: _vm.loadingData,
              disabled: _vm.isDetails
            },
            on: {
              input: function($event) {
                return _vm.clearErrorMessages(
                  "actions[0].config.products[0].type_id"
                )
              }
            },
            model: {
              value: _vm.value.config.products[0].type_id,
              callback: function($$v) {
                _vm.$set(_vm.value.config.products[0], "type_id", $$v)
              },
              expression: "value.config.products[0].type_id"
            }
          })
        ],
        1
      ),
      _c("v-col", [
        _c("p", { staticClass: "secondary--text mb-0" }, [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "forms.PromotionForm.sections.actions.generate_points.hint_details_1"
                )
              )
            )
          ]),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.$tc(
                  "forms.PromotionForm.sections.actions.generate_points.hint_details_2",
                  Number(_vm.getSimulateAmount)
                )
              )
            )
          ]),
          _vm.disableCashback
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "forms.PromotionForm.sections.actions.generate_points.hint_details_3_disable_cashback"
                      )
                    ) +
                    " "
                )
              ])
            : _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "forms.PromotionForm.sections.actions.generate_points.hint_details_3",
                        { value: _vm.getSimulateValue, type: _vm.getTypeName }
                      )
                    ) +
                    " "
                )
              ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }