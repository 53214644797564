var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "pr-md-5 py-0", attrs: { cols: "12" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "pt-0", attrs: { cols: "12" } }, [
            _c("div", { staticClass: "primary--text" }, [
              _c("b", [
                _vm._v(
                  _vm._s(_vm.$t("forms.PromotionForm.sections.triggers.title"))
                )
              ])
            ]),
            !_vm.isDetails
              ? _c("div", { staticClass: "secondary--text mb-2" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "forms.PromotionForm.sections.triggers.description"
                      )
                    )
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      ),
      _vm.firstTrigger
        ? [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "py-0", attrs: { cols: "12" } },
                  [
                    _c("cp-select-field", {
                      ref: "select_trigger_type_input",
                      attrs: {
                        placeholder: _vm.$t(
                          "forms.PromotionForm.sections.triggers.types.placeholder"
                        ),
                        label: _vm.$t(
                          "forms.PromotionForm.sections.triggers.types.label"
                        ),
                        hint: _vm.$t(
                          "forms.PromotionForm.sections.triggers.types.hint"
                        ),
                        "error-messages": _vm.getErrorMessages(
                          "triggers[0].type"
                        ),
                        items: _vm.availableTriggers,
                        "item-value": "type",
                        "item-text": "label",
                        clearable: true,
                        disabled: _vm.isDetails
                      },
                      on: {
                        input: function($event) {
                          return _vm.clearErrorMessages("triggers[0].type")
                        }
                      },
                      model: {
                        value: _vm.firstTrigger.type,
                        callback: function($$v) {
                          _vm.$set(_vm.firstTrigger, "type", $$v)
                        },
                        expression: "firstTrigger.type"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(_vm.firstTrigger.getSettingsComponent(), {
              tag: "component",
              attrs: {
                getErrorMessages: _vm.getErrorMessages,
                clearErrorMessages: _vm.clearErrorMessages,
                formType: _vm.formType
              },
              model: {
                value: _vm.firstTrigger,
                callback: function($$v) {
                  _vm.firstTrigger = $$v
                },
                expression: "firstTrigger"
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }