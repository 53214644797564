<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" md="12" class="pt-0">
                <v-row>
                    <v-row>
                        <v-col cols="12" class="pt-0">
                            <AsidePromotionInput
                                ref="aside_promotion_input"
                                v-model="value"
                                :formType="formType"
                                previewMaxHeight="118px"
                                previewMaxWidth="240px"
                            />
                        </v-col>
                    </v-row>

                    <v-col cols="12" class="py-0">
                        <cp-text-field
                            ref="name_field"
                            :label="$t('forms.PromotionForm.name.label')"
                            :hint="$t('forms.PromotionForm.name.hint')"
                            :placeholder="$t('forms.PromotionForm.name.placeholder')"
                            required type="text"
                            :error-messages="getErrorMessages('name')"
                            v-model="value.name"
                            @input="clearErrorMessages('name')"
                            :disabled="isDetails"
                        />
                    </v-col>

                    <v-col cols="12" sm="12" lg="6" class="py-0">
                        <DateInput
                            ref="start_at_field"
                            :label="$t('forms.PromotionForm.start_at.label')"
                            :hint="$t('forms.PromotionForm.start_at.hint')"
                            :placeholder="$t('forms.PromotionForm.start_at.placeholder')"
                            required type="text"
                            :error-messages="getDateErrorMessages()"
                            v-model="value.start_at"
                            @input="clearErrorMessages('start_at')"
                            outputFormat="YYYY-MM-DDTHH:mm:ssZ"
                            :disabled="isDetails"
                        />
                    </v-col>

                    <v-col cols="12" sm="12" lg="6" class="py-0">
                        <DateInput
                            ref="end_at_field"
                            :label="$t('forms.PromotionForm.end_at.label')"
                            :hint="$t('forms.PromotionForm.end_at.hint')"
                            :placeholder="$t('forms.PromotionForm.end_at.placeholder')"
                            required type="text"
                            :error-messages="getErrorMessages('end_at')"
                            v-model="value.end_at"
                            @input="clearErrorMessages('end_at')"
                            outputFormat="YYYY-MM-DDTHH:mm:ssZ"
                            :disabled="isDetails"
                        />
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" class="py-0">
                        <cp-textarea
                            ref="description_field"
                            :label="$t('forms.PromotionForm.description.label')"
                            :hint="$t('forms.PromotionForm.description.hint')"
                            :placeholder="$t('forms.PromotionForm.description.placeholder')"
                            required type="text"
                            :error-messages="getErrorMessages('description')"
                            v-model="value.description"
                            rows="4"
                            @input="clearErrorMessages('description')"
                            @keyup.enter="$emit('triggerSubmit')"
                            :disabled="isDetails"
                        />
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" class="py-0">
                        <cp-text-field
                            ref="promo_url_field"
                            :label="$t('forms.PromotionForm.promo_url.label')"
                            :hint="$t('forms.PromotionForm.promo_url.hint')"
                            :placeholder="$t('forms.PromotionForm.promo_url.placeholder')"
                            required type="text"
                            :error-messages="getErrorMessages('promo_url')"
                            v-model="value.promo_url"
                            @input="clearErrorMessages('promo_url')"
                            :disabled="isDetails"
                        />
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" md="12" class="pt-0">
                <v-row>
                    <TriggersFieldset v-model="value" v-if="value.triggers.length" class="py-3" :formType="formType" />
                    <FiltersFieldset  v-model="value" class="py-3" :formType="formType" />
                    <ActionsFieldset  v-model="value" v-if="value.actions.length"  class="py-3" :formType="formType" @close-modal="$emit('close-modal')"/>
                </v-row>
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

    </v-form>
</template>

<script>
import FormMixin           from '@/mixins/FormMixin'
import Promotion           from '@/models/Promotion'
import AsidePromotionInput from './commons/AsidePromotionInput'
import DateInput           from '@/shared/components/inputs/DateInput'
import TriggersFieldset    from '@/components/forms/fieldset/promotion/TriggersPromotionFieldset'
import FiltersFieldset     from '@/components/forms/fieldset/promotion/FiltersPromotionFieldset'
import ActionsFieldset     from '@/components/forms/fieldset/promotion/ActionsPromotionFieldset'
import PromotionTrigger    from '@/models/PromotionTrigger'
import PromotionFilter     from '@/models/PromotionFilter'
import PromotionAction     from '@/models/PromotionAction'

export default {
    name: 'PromotionForm',
    mixins: [ FormMixin(Promotion, 'name_field') ],
    components: {
        AsidePromotionInput, DateInput, TriggersFieldset, FiltersFieldset, ActionsFieldset
    },
    props: {
        Promotion: Object,
        formType: String,
    },
    formType: {
        type: String,
        required: false,
        default: 'CREATE',
    },
    data: vm => ({
        photoPreview: null,
    }),
    created() {
        if (this.isDetails)
            return

        if (this.value.triggers && !this.value.triggers.length) {
            this.$set(this.value, 'triggers', [])
            this.$set(this.value.triggers, 0, new PromotionTrigger())
        }

        if (this.value.filters && !this.value.filters.length) {
            this.$set(this.value, 'filters', [])
            this.$set(this.value.filters, 0, new PromotionFilter())
        }

        if (this.value.actions && !this.value.actions.length) {
            const product = { products : [{ type_id: 'default', value: 1 }] }
            this.$set(this.value, 'actions', [])
            this.$set(this.value.actions, 0, new PromotionAction({ config: product }))
        }
    },
    methods: {
        presubmit() {
            // Salva o preview da foto
            this.photoPreview    = this.value.photo_url
            this.value.photo_url = null
            return true
        },
        postsubmit() {
            // Recupera o preview da foto
            this.value.photo_url = this.photoPreview
            this.photoPreview    = null
            return true
        },
        async triggerFileUploads() {
            await this.$refs.aside_promotion_input.triggerFileUploads()
        },
        onInput(attributeName, value) {
            this.$set(this.value, attributeName, value)
        },
        getDateErrorMessages() {
            let start_at = this.value.start_at
            let end_at   = this.value.end_at

            if (start_at && end_at && start_at > end_at) {
                return [this.$t('forms.PromotionForm.start_at.error')].concat(this.getErrorMessages('start_at'))
            }

            return this.getErrorMessages('start_at')
        },
    },
    computed: {
        isDetails() {
            return this.formType == 'DETAILS'
        },
    },
}
</script>