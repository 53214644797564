<template>
    <BaseModal ref="modal"
        @cancel="cancel" @confirm="confirm"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        :max-width="$vuetify.breakpoint.mdAndUp ? '570px' : '330px'"
    >
        <PromotionForm
            ref="promotion_form"
            v-model="promotion"
            :formType="'DETAILS'"
        />
    </BaseModal>
</template>

<script>
import BaseModal            from '@/components/modals/BaseModal'
import BaseModalMixin       from '../BaseModalMixin'
import Promotion            from '@/models/Promotion'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import PromotionForm from '@/components/forms/PromotionForm'

export default {
    name: 'PromotionsDetailsModal',
    mixins: [ BaseModalMixin, HasErrorHandlerMixin ],
    components: { BaseModal, PromotionForm },
    props: {
        promotion: {
            type: Promotion,
            required: true,
        },
    },
    data: vm => {
        return {
            title: vm.$t('views.promotions.Details.title'),
            useKey: false,
            type_name: '',
        }
    },
    computed: {
        computedConfirmButton() {
            return {
                label: this.$t('actions.close'),
            }
        },
        computedCancelButton() {
            return {
                hide: true,
            }
        },
    },
}
</script>