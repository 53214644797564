import BaseModel from './BaseModel'
// import { required, string, length, isnil, boolean, url, before, after, date } from '../utils/validation'
import { required, string, length, isnil, boolean, url, before_or_equal, after_or_equal, date } from '../utils/validation' // Implementar na FID-1143
import i18n from '@/i18n'
import HasDateRange from './mixins/HasDateRange'
import { mix } from 'mixwith'

export default class Promotion extends mix(BaseModel).with(HasDateRange({ from_field: 'start_at', to_field: 'end_at' })) {

    defaults() {
        return {
            id             : undefined,
            name           : undefined,
            description    : undefined,
            start_at       : undefined,
            end_at         : undefined,
            promo_url      : undefined,
            photo_url      : undefined,
            show_in_modal  : false, // [TODO] Avaliar, parece que não está sendo utilizado
            created_at     : undefined,
            updated_at     : undefined,
        }
    }

    validation() {
        return {
            name         : required.and(string).and(length(1, 191)),
            description  : (string.and(length(1, 600))).or(isnil),
            // start_at     : this.end_at   ? required.and(date).and(before(this.end_at))  : required.and(date),
            // end_at       : this.start_at ? required.and(date).and(after(this.start_at)) : required.and(date),
            start_at     : this.end_at   ? required.and(date).and(before_or_equal(this.end_at))  : required.and(date), // Implementar na FID-1143
            end_at       : this.start_at ? required.and(date).and(after_or_equal(this.start_at)) : required.and(date), // Implementar na FID-1143
            promo_url    : (string.and(url).and(length(1, 600))).or(isnil),
            photo_url    : (string.and(url).and(length(1, 2000))).or(isnil),
            show_in_modal: boolean,
        }
    }

    static async list(params = {}, additionalConfig = {}) {
        let response = await this.request({
            url   : `/promotion`,
            method: 'get',
            params,
            ...additionalConfig,
        })

        if (!response)
            return

        return response.data.map(d => new this(d))
    }

    static async get(promotionId) {
        let response = await this.request({
            url   : `/promotion/${promotionId}`,
            method: 'get',
        })

        if (!response)
            return null

        return response.data ? new this(response.data) : null
    }

    /**
     * Retorna uma label contendo o período de início e fim da promoção
     *
     * @returns {string}
     */
    getRangeLabel() {
        // [TODO] Criar traduções no shared
        // return i18n.t('shared.models.Promotion.range.format', { start_at: this.getStartAtLabel(), end_at: this.getEndAtLabel() })

        if (this.isRangeClosed())
            return i18n.t('models.Promotion.range.format', { start_at: this.getStartAtLabel(), end_at: this.getEndAtLabel() })
        if (this.hasRangeFrom())
            return i18n.t('models.Promotion.range.from', { start_at: this.getStartAtLabel() })
        if (this.hasRangeTo())
            return i18n.t('models.Promotion.range.to', { end_at: this.getEndAtLabel() })
    }
}