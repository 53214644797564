<template>
    <v-col cols="12" class="pr-md-5 py-0">
        <v-row>
            <v-col cols="12" class="py-0">
                <div class="primary--text"><b>{{ $t('forms.PromotionForm.sections.filters.title') }}</b></div>
                <div v-if="!isDetails" class="secondary--text mb-2">{{ $t('forms.PromotionForm.sections.filters.description') }}</div>
            </v-col>
        </v-row>

        <div v-for="(filter, index) in value.filters" :key="index" class="pt-3">
            <v-row>
                <v-col cols="12" class="py-0">
                    <cp-select-field
                        ref="select_filter_type_input"
                        :placeholder="$t('forms.PromotionForm.sections.filters.types.placeholder')"
                        :label="$t('forms.PromotionForm.sections.filters.types.label')"
                        :hint="$t('forms.PromotionForm.sections.filters.types.hint')"
                        :error-messages="getErrorMessages(`filters[0].type`)"
                        :items="getAvailableFiltersFor(index)"
                        item-value="type"
                        item-text="label"
                        v-model="filter.type"
                        :clearable="true"
                        @input="clearErrorMessages(`filters[0].type`)"
                        :disabled="isDetails"
                    >
                    </cp-select-field>
                </v-col>
            </v-row>

            <component
                :is="filter.getSettingsComponent()"
                v-model="value.filters[index]"
                :getErrorMessages="getErrorMessages"
                :clearErrorMessages="clearErrorMessages"
                :formType="formType"
                :index="index"
            />

            <v-btn v-if="!isDetails" @click="removeFilter(index)" text class="background error--text mb-3">{{ $t('forms.PromotionForm.sections.filters.remove_filter') }}</v-btn>

            <v-divider></v-divider>
        </div>

        <v-row>
            <v-col cols="12" class="">
                <v-btn v-if="!isDetails" @click="addFilter" text class="background primary--text">{{ $t('forms.PromotionForm.sections.filters.add_filter') }}</v-btn>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import FormMixin from '@/mixins/FormMixin'
import Promotion from '@/models/Promotion'
import PromotionFilter from '@/models/PromotionFilter'

export default {
    name: 'FilterPromotionFieldset',
    mixins: [ FormMixin(Promotion) ],
    props: {
        value: Promotion,
        formType: {
            type: String,
            required: false,
            default: 'CREATE',
        },
    },
    methods: {
        addFilter() {
            this.value.filters.push(new PromotionFilter)
        },
        removeFilter(index) {
            this.value.filters.splice(index, 1)
        },
        getAvailableFiltersFor(index) {
            return this.availableFilters.concat(this.value.filters[index].type ? [this.value.filters[index]] : [])
        },
    },
    computed: {
        availableFilters() {
            return PromotionFilter.getAvailableFilters(this.value.filters)
        },
        isDetails() {
            return this.formType == 'DETAILS'
        },
    },
}
</script>

<style>

</style>