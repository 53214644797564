var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        confirmButton: _vm.computedConfirmButton,
        cancelButton: _vm.computedCancelButton,
        persistent: "",
        "max-width": _vm.$vuetify.breakpoint.mdAndUp ? "570px" : "330px"
      },
      on: { cancel: _vm.cancel, confirm: _vm.triggerSubmit },
      model: {
        value: _vm.isModalOpen,
        callback: function($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen"
      }
    },
    [
      _c("PromotionForm", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loading,
            expression: "!loading"
          }
        ],
        ref: "promotion_form",
        attrs: {
          isFormValid: _vm.isFormValid,
          formType: "CREATE",
          loading: _vm.loading
        },
        on: {
          "update:isFormValid": function($event) {
            _vm.isFormValid = $event
          },
          "update:is-form-valid": function($event) {
            _vm.isFormValid = $event
          },
          submit: _vm.confirm,
          triggerSubmit: _vm.triggerSubmit,
          "close-modal": function($event) {
            _vm.isModalOpen = false
          }
        },
        model: {
          value: _vm.promotion,
          callback: function($$v) {
            _vm.promotion = $$v
          },
          expression: "promotion"
        }
      }),
      _vm.loading
        ? _c("span", [_vm._v(_vm._s(_vm.$t("forms.wait")))])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }