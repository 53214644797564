const moment = require('moment-timezone')
const _      = require('lodash')

/**
 * Este é um modelo útil para validações de intervalos entre datas
 */
module.exports = class DateRange {
    constructor(data = {}) {
        this._fill(data)
    }

    _fill(data = {}) {
        this.from = data.from ? moment.isMoment(data.from) ? data.from : this.constructor.parseDate(data.from) : null
        this.to   = data.to   ? moment.isMoment(data.to)   ? data.to   : this.constructor.parseDate(data.to)   : null
    }

    /**
     * Verifica se ambas as datas do intervalo (se presentes) são válidas
     *
     * @returns {boolean}
     */
    isValid() {
        let fromIsValid  = (!this.hasFrom() || (moment.isMoment(this.from) && this.from.isValid()))
        let toIsValid    = (!this.hasTo()   || (moment.isMoment(this.to)   && this.to.isValid()))
        let orderIsValid = !(this.hasFrom() && this.hasTo() && !this.from.isSameOrBefore(this.to))

        return fromIsValid && toIsValid && orderIsValid
    }

    /**
     * Indica se este intervalo de tempo é fechado, ou seja, tem um limite inferior e superior
     *
     * @returns {boolean}
     */
    isClosed() {
        return !this.isOpen()
    }

    /**
     * Indica se este intervalo de tempo é aberto, ou seja, não tem um limite inferior ou superior
     *
     * @returns {boolean}
     */
    isOpen() {
        return !this.hasFrom() || !this.hasTo()
    }

    /**
     * Indica se este qualquer um dos limites está definido
     *
     * @returns {boolean}
     */
    isSet() {
        return this.hasFrom() || this.hasTo()
    }

    /**
     * Avalia se a data passada como argumento está entre os limites de data deste modelo
     *
     * @argument {moment} dt Instância do moment da data de argumento. Por padrão considera a data corrente.
     * @returns {boolean}
     */
    isBetweenRange(dt = moment()) {
        if (this.hasFrom() && moment(dt).isBefore(this.from))
            return false

        if (this.hasTo() && moment(dt).isAfter(this.to))
            return false

        return true
    }

    /**
     * Indica se este intervalo de tempo tem limite inferior
     *
     * @returns {boolean}
     */
    hasFrom() {
        return !!this.from
    }

    /**
     * Indica se este intervalo de tempo tem limite superior
     *
     * @returns {boolean}
     */
    hasTo() {
        return !!this.to
    }

    /**
     * Altera as datas limite para os limites dos respectivos <limit>, se presentes
     *
     * @argument {string} limit especifica o intervalo para arredondar
     */
    moveToLimit(limit = 'day') {
        if (this.hasFrom())
            this.from = this.from.startOf(limit)
        if (this.hasTo())
            this.to = this.to.endOf(limit)
    }

    // Comentando pois pode ser útil no futuro
    // /**
    //  * Retorna o intervalo em forma de timestamp
    //  *
    //  * @returns {object}
    //  */
    // toTimestamp() {
    //     let returnValue = {}
    //     if (this.hasFrom())
    //         returnValue.from = _.padStart(this.from.valueOf(), TIMESTAMP_DIGITS, '0')
    //     if (this.hasTo())
    //         returnValue.to = _.padStart(this.to.valueOf(), TIMESTAMP_DIGITS, '0')
    //     return returnValue
    // }

    /**
     * Retorna o intervalo em forma de objeto
     *
     * @returns {object}
     */
    toObject() {
        return {
            from: this.from,
            to  : this.to,
        }
    }

    // Comentando pois pode ser útil no futuro
    // static parseDate(input) {
    //     const recommendedFormats = [
    //         'YYYY-MM-DDZ',
    //         'YYYY-MM-DDTHH:mmZ',
    //         'YYYY-MM-DDTHH:mm:ssZ',
    //     ]
    //     const noTZFormats = [
    //         'YYYY-MM-DD',
    //         'YYYY-MM-DDTHH:mm',
    //         'YYYY-MM-DDTHH:mm:ss',
    //     ]

    //     // Primeiro, tenta fazer o parse com datas com timezone
    //     let momentObject
    //     for (let format of recommendedFormats) {
    //         momentObject = moment(input, format, true)
    //         if (momentObject.isValid())
    //             return momentObject
    //     }

    //     // Depois tenta os formatos sem timezone, para aplicar a conversão
    //     // [COMPAT] Apenas para compatibilidade
    //     for (let format of noTZFormats) {
    //         momentObject = moment(input, format, true)
    //         if (momentObject.isValid()) {
    //             $console.warn('WARN: datetime formats without timezone are not recommended')

    //             // Se tem Client-TZ, aplica a conversão
    //             let tz_iana = ContextSingleton.get('tz_iana')
    //             if (tz_iana)
    //                 return moment.tz(input, format, tz_iana)

    //             return momentObject
    //         }
    //     }

    //     // Retorna um moment inválido, vai ser tratado depois
    //     return momentObject
    // }
}