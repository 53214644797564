<template>
    <v-chip
        :color="computedColor"
        v-on="listeners"
        v-bind="attrs"
    >
        <span :class="labelClass">{{ computedLabel }}</span>
    </v-chip>
</template>

<script>
export default {
    name: 'ActiveChip',
    inheritAttrs: false,
    props: {
        value     : Boolean,
        trueLabel : String,
        falseLabel: String,
        labelClass: String,
        trueColor : {
            type: String,
            default: () => 'green'
        },
        falseColor : {
            type: String,
            default: () => 'red'
        },
    },
    computed: {
        computedLabel() {
            return this.value ? this.trueLabel : this.falseLabel
        },
        computedColor() {
            return this.value ? this.trueColor : this.falseColor
        },
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, ...listeners } = this.$listeners
            return listeners
        },

        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
}
</script>