<template>
    <v-row>
        <v-col cols="12" class="py-0">
            <cp-text-field 
                :label="$t('forms.PromotionForm.sections.triggers.internal_link.label')"
                :placeholder="$t('forms.PromotionForm.sections.triggers.internal_link.placeholder')"
                :hint="$t('forms.PromotionForm.sections.triggers.internal_link.hint')"
                type="text"
                v-model="value.config.custom_internal_link_code"
                :error-messages="getErrorMessages('triggers[0].config.custom_internal_link_code')"
                @input="clearErrorMessages('triggers[0].config.custom_internal_link_code')"
                :disabled="isDetails"
            />
        </v-col>

        <v-col>
            <p v-show="computedCode" class="secondary--text mb-0" v-html="$t('forms.PromotionForm.sections.triggers.internal_link.hint_details', { link: computedLink })"></p>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'PromotionTriggerInternalLinkSettings',
    props: {
        value             : Object,
        getErrorMessages  : Function,
        clearErrorMessages: Function,
        formType: {
            type: String,
            required: false,
            default: 'CREATE',
        },
    },
    computed: {
        isDetails() {
            return this.formType == 'DETAILS'
        },
        computedLink() {
            return this.frontend_domain + '/promo/t/' + (this.computedCode)
        },
        computedCode() {
            return (this.value.config.custom_internal_link_code || this.value.promotion_id)
        },
        ...mapGetters({
            frontend_domain: 'company/frontend_domain',
        }),
    },
}
</script>