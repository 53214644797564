import BaseModel from './BaseModel'
import $constants from '@/services/constants'
import PromotionTriggerInternalLinkSettings from '@/components/forms/fieldset/promotion/trigger_settings/PromotionTriggerInternalLinkSettings'

export default class PromotionTrigger extends BaseModel {
    defaults() {
        return {
            id          : undefined,
            type        : undefined,   // enum: PROMOTION_TYPE_BANNER_CLICK, PROMOTION_TYPE_INTERNAL_LINK
            promotion_id: undefined,   // definido automaticamente via parent_link
            config      : {
                custom_internal_link_code: null,   // Quando: PROMOTION_TYPE_INTERNAL_LINK
            },
        }
    }

    /**
     * Retorna o componente que configura um gatilho de promoção deste tipo
     *
     * @returns {VueComponent}
     */
    getSettingsComponent() {
        return ({
            [$constants.getConstant('PROMOTION_TYPE_INTERNAL_LINK')]: PromotionTriggerInternalLinkSettings,
        })[this.type]
    }
}