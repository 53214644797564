var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "pr-md-5 py-0", attrs: { cols: "12" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
            _c("div", { staticClass: "primary--text" }, [
              _c("b", [
                _vm._v(
                  _vm._s(_vm.$t("forms.PromotionForm.sections.filters.title"))
                )
              ])
            ]),
            !_vm.isDetails
              ? _c("div", { staticClass: "secondary--text mb-2" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("forms.PromotionForm.sections.filters.description")
                    )
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      ),
      _vm._l(_vm.value.filters, function(filter, index) {
        return _c(
          "div",
          { key: index, staticClass: "pt-3" },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "py-0", attrs: { cols: "12" } },
                  [
                    _c("cp-select-field", {
                      ref: "select_filter_type_input",
                      refInFor: true,
                      attrs: {
                        placeholder: _vm.$t(
                          "forms.PromotionForm.sections.filters.types.placeholder"
                        ),
                        label: _vm.$t(
                          "forms.PromotionForm.sections.filters.types.label"
                        ),
                        hint: _vm.$t(
                          "forms.PromotionForm.sections.filters.types.hint"
                        ),
                        "error-messages": _vm.getErrorMessages(
                          "filters[0].type"
                        ),
                        items: _vm.getAvailableFiltersFor(index),
                        "item-value": "type",
                        "item-text": "label",
                        clearable: true,
                        disabled: _vm.isDetails
                      },
                      on: {
                        input: function($event) {
                          return _vm.clearErrorMessages("filters[0].type")
                        }
                      },
                      model: {
                        value: filter.type,
                        callback: function($$v) {
                          _vm.$set(filter, "type", $$v)
                        },
                        expression: "filter.type"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(filter.getSettingsComponent(), {
              tag: "component",
              attrs: {
                getErrorMessages: _vm.getErrorMessages,
                clearErrorMessages: _vm.clearErrorMessages,
                formType: _vm.formType,
                index: index
              },
              model: {
                value: _vm.value.filters[index],
                callback: function($$v) {
                  _vm.$set(_vm.value.filters, index, $$v)
                },
                expression: "value.filters[index]"
              }
            }),
            !_vm.isDetails
              ? _c(
                  "v-btn",
                  {
                    staticClass: "background error--text mb-3",
                    attrs: { text: "" },
                    on: {
                      click: function($event) {
                        return _vm.removeFilter(index)
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "forms.PromotionForm.sections.filters.remove_filter"
                        )
                      )
                    )
                  ]
                )
              : _vm._e(),
            _c("v-divider")
          ],
          1
        )
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              !_vm.isDetails
                ? _c(
                    "v-btn",
                    {
                      staticClass: "background primary--text",
                      attrs: { text: "" },
                      on: { click: _vm.addFilter }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "forms.PromotionForm.sections.filters.add_filter"
                          )
                        )
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }