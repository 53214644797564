import { Mixin } from 'mixwith'
import _         from 'lodash'
import i18n      from '@/i18n'
import moment    from 'moment'
import DateRange from '../DateRange'

/**
 * Retorna um mixin que aplica métodos úteis de intervalo de tempo a um modelo.
 *
 * @argument {object} mixin_config            contém especificações sobre particularidades do modelo
 * @argument {string} mixin_config.from_field indica o campo que representa o início do intervalo
 * @argument {string} mixin_config.to_field   indica o campo que representa o final do intervalo
 *
 * @returns {Class}
 */
export default (mixin_config = {}) => {
    const { from_field, to_field } = _.defaultTo(mixin_config, { from_field: 'from', to_field: 'to' })

    return Mixin((superclass) => class HasDateRangeMixin extends superclass {

        getStartAtLabel() {
            return moment(this[from_field]).format(i18n.t('formats.moment_date'))
        }
    
        getEndAtLabel() {
            return moment(this[to_field]).format(i18n.t('formats.moment_date'))
        }

        // Faz o de-para dos atributos com o objeto date range

        set [from_field](v) {
            if (!this._date_range) {
                this._date_range = new DateRange()
            }

            this._date_range.from = v
        }
        set [to_field](v) {
            if (!this._date_range) {
                this._date_range = new DateRange()
            }

            this._date_range.to = v
        }
        get [from_field]() {
            return this._date_range.from
        }
        get [to_field]() {
            return this._date_range.to
        }

        // Faz o de-para dos métodos com o objeto date range

        isRangeValid() { return this._date_range.isValid(...arguments) }
        isRangeClosed() { return this._date_range.isClosed(...arguments) }
        isRangeOpen() { return this._date_range.isOpen(...arguments) }
        isRangeSet() { return this._date_range.isSet(...arguments) }
        isRangeBetweenRange() { return this._date_range.isBetweenRange(...arguments) }
        hasRangeFrom() { return this._date_range.hasFrom(...arguments) }
        hasRangeTo() { return this._date_range.hasTo(...arguments) }
        moveRangeToLimit() { return this._date_range.moveToLimit(...arguments) }
    })
}
