<template>
    <v-container fluid class="pt-0">
        <CreateModal  ref="create_modal"  :promotion="selectedPromotion" />
        <DetailsModal ref="details_modal" :promotion="selectedPromotion" />
        <EditModal    ref="edit_modal"    :promotion="selectedPromotion" />

        <span class="pl-5 pl-md-12 section-title">{{ $t('views.promotions.title') }}</span>
        <v-alert v-if="hasError" type="error" class="mt-4 mx-5 mt-md-6 mx-md-12">
            {{ $t('errors.generic') }}
        </v-alert>
        <PromotionsTable
            v-else
            ref="table"
            class="my-4 mx-2 mx-md-10"
            :fetchFunction="fetchFunction"
            :loading="loading"
            @create="openCreate"
            @details="openDetails"
            @edit="openEdit"
            @delete="handleDelete"
        />
    </v-container>
</template>

<script>
import CreateModal          from '@/components/modals/promotions/CreateModal'
import DetailsModal         from '@/components/modals/promotions/DetailsModal'
import EditModal            from '@/components/modals/promotions/EditModal'
import PromotionsTable      from '@/components/tables/PromotionsTable'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import Promotion            from '@/models/Promotion'

export default {
    data: () => ({
        selectedPromotion: new Promotion,
        hasError         : false,
        loading          : false,
    }),
    mixins: [ HasErrorHandlerMixin ],
    components: { PromotionsTable, CreateModal, DetailsModal, EditModal },
    methods: {
        async openCreate() {
            this.selectedPromotion = new Promotion()
            let r = await this.$refs.create_modal.open()
            if (!r)
                return

            this.$bus.$emit('message', this.$t('views.promotions.List.alerts.create_success'), 'success')
            await this.refreshTable()
        },
        async openDetails(promotion) {
            this.loading = true
            this.selectedPromotion = await Promotion.get(promotion.id)
            this.loading = false
            this.$refs.details_modal.open()
        },
        async openEdit(promotion) {
            this.loading = true
            this.selectedPromotion = await Promotion.get(promotion.id)
            this.loading = false
            let r = await this.$refs.edit_modal.open()
            if (!r)
                return

            this.$bus.$emit('message', this.$t('views.promotions.List.alerts.edit_success'), 'success')
            await this.refreshTable()
        },
        async handleDelete(promotion) {
            await promotion.delete()
                .catch(this.preErrorHandler)

            if (this.hasError) {
                this.hasError = false
                return
            }

            this.$bus.$emit('message', this.$t('views.promotions.List.alerts.delete_success'), 'success')
            await this.refreshTable()
        },
        async refreshTable() {
            await this.$refs.table.refresh()
        },
        async fetchFunction(pagination) {
            this.hasError = false
            let response  = await Promotion.list(pagination)
                .catch(this.preErrorHandler)
            return Promotion.hydrate(response)
        },
        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
    }
}
</script>