import SharedPromotionAction from '@/shared/models/PromotionAction'
import $constants from '@/services/constants'
import PromotionActionPointsGenerateSettings from '@/components/forms/fieldset/promotion/action_settings/PromotionActionPointsGenerateSettings'

export default class PromotionAction extends SharedPromotionAction {
    defaults() {
        return {
            ...super.defaults(),
        }
    }

    getSettingsComponent() {
        return ({
            [$constants.getConstant('PROMOTION_ACTION_TYPE_GENERATE_POINTS')]: PromotionActionPointsGenerateSettings,
        })[this.type]
    }
}