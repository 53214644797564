var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseCrudTable",
    _vm._g(
      _vm._b(
        {
          ref: "table",
          attrs: {
            tableName: "PromotionsTable",
            headerConfigs: _vm.headerConfigs,
            fetchFunction: _vm.fetchFunction,
            pagination: _vm.pagination
          },
          scopedSlots: _vm._u([
            {
              key: "item.start_at",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(" " + _vm._s(_vm._f("dateUTC")(item.start_at)) + " ")
                ]
              }
            },
            {
              key: "item.end_at",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(" " + _vm._s(_vm._f("dateUTC")(item.end_at)) + " ")
                ]
              }
            },
            {
              key: "item.is_active",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("BooleanChip", {
                    attrs: {
                      small: "",
                      label: "",
                      value: item.is_active,
                      "true-label": _vm.$t("globals.active"),
                      "false-label": _vm.$t("globals.inactive"),
                      "label-class": "white--text"
                    }
                  })
                ]
              }
            }
          ])
        },
        "BaseCrudTable",
        _vm.attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }