<template>
    <v-row>
        <v-col cols="12" class="py-0">
            <cp-text-field 
                :placeholder="$t('forms.PromotionForm.sections.filters.new_register.placeholder')"
                :label="$t('forms.PromotionForm.sections.filters.new_register.label')"
                :hint="$t('forms.PromotionForm.sections.filters.new_register.hint')"
                v-model.lazy="formatTimePast"
                type="number"
                :rules="gtz"
                :error-messages="getErrorMessages(`filters[${index}].config.new_register`)"
                @input="clearErrorMessages(`filters[${index}].config.new_register`)"
                :disabled="isDetails"
            />
        </v-col>

        <v-col>
            <p class="secondary--text mb-0" v-html="$tc('forms.PromotionForm.sections.filters.new_register.hint_details', [formatTimePast])"></p>
        </v-col>
    </v-row>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import { gt } from '@/utils/validation'

export default {
    name: 'PromotionFilterTimePastSettings',
    props: {
        index             : {
            type      : Number,
            validation: (v) => _.isInteger(v)
        },
        value             : Object,
        getErrorMessages  : Function,
        clearErrorMessages: Function,
        formType          : {
            type: String,
            required: false,
            default: 'CREATE',
        },
    },
    data: vm => ({
        gtz : [gt(0)],
    }),
    computed: {
        formatTimePast: {
            get(vm) {
                let time = vm.value.config.time_past
                return time ? moment.duration(Number(time), "minutes").asHours() : ''
            },
            set(hour) {
                this.value.config.time_past = moment.duration(Number(hour), "hours").asMinutes()
            }
        },
        isDetails() {
            return this.formType == 'DETAILS'
        },
    },
}
</script>