<template>
    <v-col cols="12" class="pr-md-5 py-0">
        <v-row>
            <v-col cols="12" class="py-0">
                <div class="primary--text"><b>{{ $t('forms.PromotionForm.sections.actions.title') }}</b></div>
                <div v-if="!isDetails" class="secondary--text mb-2">{{ $t('forms.PromotionForm.sections.actions.description') }}</div>
            </v-col>
        </v-row>

        <template v-if="firstAction">
            <v-row>
                <v-col cols="12" class="py-0">
                    <cp-select-field
                        ref="select_type_action_input"
                        :placeholder="$t('forms.PromotionForm.sections.actions.types.placeholder')"
                        :label="$t('forms.PromotionForm.sections.actions.types.label')"
                        :hint="$t('forms.PromotionForm.sections.actions.types.hint')"
                        :error-messages="getErrorMessages('type_action_select')"
                        :items="availableActions"
                        item-value="type"
                        item-text="label"
                        v-model="firstAction.type"
                        :clearable="true"
                        @input="clearErrorMessages('type_action_select')"
                        :disabled="isDetails"
                    />
                </v-col>
            </v-row>

            <component
                :is="firstAction.getSettingsComponent()"
                v-model="firstAction"
                :getErrorMessages="getErrorMessages"
                :clearErrorMessages="clearErrorMessages"
                :formType="formType"
                @close-modal="$emit('close-modal')"
            />
        </template>
    </v-col>
</template>

<script>
import FormMixin from '@/mixins/FormMixin'
import Promotion from '@/models/Promotion'

export default {
    name: 'ActionPromotionFieldset',
    mixins: [ FormMixin(Promotion, '') ],
    props: {
        value: Object,
        formType: {
            type: String,
            required: false,
            default: 'CREATE',
        },
    },
    computed: {
        availableActions() {
            return [
                { label: this.$t('forms.PromotionForm.sections.actions.types.generate_points'), type: this.$constants.getConstant('PROMOTION_ACTION_TYPE_GENERATE_POINTS') },
            ]
        },
        firstAction() {
            return this.$lodash.get(this, 'value.actions[0]')
        },
        isDetails() {
            return this.formType == 'DETAILS'
        },
    },
}
</script>

<style>

</style>