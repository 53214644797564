var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "12", md: "12" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "12" } },
                        [
                          _c("AsidePromotionInput", {
                            ref: "aside_promotion_input",
                            attrs: {
                              formType: _vm.formType,
                              previewMaxHeight: "118px",
                              previewMaxWidth: "240px"
                            },
                            model: {
                              value: _vm.value,
                              callback: function($$v) {
                                _vm.value = $$v
                              },
                              expression: "value"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "name_field",
                        attrs: {
                          label: _vm.$t("forms.PromotionForm.name.label"),
                          hint: _vm.$t("forms.PromotionForm.name.hint"),
                          placeholder: _vm.$t(
                            "forms.PromotionForm.name.placeholder"
                          ),
                          required: "",
                          type: "text",
                          "error-messages": _vm.getErrorMessages("name"),
                          disabled: _vm.isDetails
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("name")
                          }
                        },
                        model: {
                          value: _vm.value.name,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "name", $$v)
                          },
                          expression: "value.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", sm: "12", lg: "6" }
                    },
                    [
                      _c("DateInput", {
                        ref: "start_at_field",
                        attrs: {
                          label: _vm.$t("forms.PromotionForm.start_at.label"),
                          hint: _vm.$t("forms.PromotionForm.start_at.hint"),
                          placeholder: _vm.$t(
                            "forms.PromotionForm.start_at.placeholder"
                          ),
                          required: "",
                          type: "text",
                          "error-messages": _vm.getDateErrorMessages(),
                          outputFormat: "YYYY-MM-DDTHH:mm:ssZ",
                          disabled: _vm.isDetails
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("start_at")
                          }
                        },
                        model: {
                          value: _vm.value.start_at,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "start_at", $$v)
                          },
                          expression: "value.start_at"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", sm: "12", lg: "6" }
                    },
                    [
                      _c("DateInput", {
                        ref: "end_at_field",
                        attrs: {
                          label: _vm.$t("forms.PromotionForm.end_at.label"),
                          hint: _vm.$t("forms.PromotionForm.end_at.hint"),
                          placeholder: _vm.$t(
                            "forms.PromotionForm.end_at.placeholder"
                          ),
                          required: "",
                          type: "text",
                          "error-messages": _vm.getErrorMessages("end_at"),
                          outputFormat: "YYYY-MM-DDTHH:mm:ssZ",
                          disabled: _vm.isDetails
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("end_at")
                          }
                        },
                        model: {
                          value: _vm.value.end_at,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "end_at", $$v)
                          },
                          expression: "value.end_at"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", xs: "12", sm: "12" }
                    },
                    [
                      _c("cp-textarea", {
                        ref: "description_field",
                        attrs: {
                          label: _vm.$t(
                            "forms.PromotionForm.description.label"
                          ),
                          hint: _vm.$t("forms.PromotionForm.description.hint"),
                          placeholder: _vm.$t(
                            "forms.PromotionForm.description.placeholder"
                          ),
                          required: "",
                          type: "text",
                          "error-messages": _vm.getErrorMessages("description"),
                          rows: "4",
                          disabled: _vm.isDetails
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("description")
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.$emit("triggerSubmit")
                          }
                        },
                        model: {
                          value: _vm.value.description,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "description", $$v)
                          },
                          expression: "value.description"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", xs: "12", sm: "12" }
                    },
                    [
                      _c("cp-text-field", {
                        ref: "promo_url_field",
                        attrs: {
                          label: _vm.$t("forms.PromotionForm.promo_url.label"),
                          hint: _vm.$t("forms.PromotionForm.promo_url.hint"),
                          placeholder: _vm.$t(
                            "forms.PromotionForm.promo_url.placeholder"
                          ),
                          required: "",
                          type: "text",
                          "error-messages": _vm.getErrorMessages("promo_url"),
                          disabled: _vm.isDetails
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("promo_url")
                          }
                        },
                        model: {
                          value: _vm.value.promo_url,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "promo_url", $$v)
                          },
                          expression: "value.promo_url"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "12", md: "12" } },
            [
              _c(
                "v-row",
                [
                  _vm.value.triggers.length
                    ? _c("TriggersFieldset", {
                        staticClass: "py-3",
                        attrs: { formType: _vm.formType },
                        model: {
                          value: _vm.value,
                          callback: function($$v) {
                            _vm.value = $$v
                          },
                          expression: "value"
                        }
                      })
                    : _vm._e(),
                  _c("FiltersFieldset", {
                    staticClass: "py-3",
                    attrs: { formType: _vm.formType },
                    model: {
                      value: _vm.value,
                      callback: function($$v) {
                        _vm.value = $$v
                      },
                      expression: "value"
                    }
                  }),
                  _vm.value.actions.length
                    ? _c("ActionsFieldset", {
                        staticClass: "py-3",
                        attrs: { formType: _vm.formType },
                        on: {
                          "close-modal": function($event) {
                            return _vm.$emit("close-modal")
                          }
                        },
                        model: {
                          value: _vm.value,
                          callback: function($$v) {
                            _vm.value = $$v
                          },
                          expression: "value"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }