<template>
    <v-row>
        <v-col cols="12" class="py-0">
            <div v-if="isDetails">
                <v-img :src="value.photo_url" :max-height="$vuetify.breakpoint.mdAndUp ? previewMaxHeight : ''" :max-width="previewMaxWidth" class="mx-auto pointer image-preview" contain></v-img>
            </div>
            <div v-else>
                <ImageInput
                    ref="photo_field"
                    :previewMaxHeight="previewMaxHeight"
                    :previewMaxWidth="previewMaxWidth"
                    v-model="value.photo_url"
                    type="update-promotion-photo"
                    :params="{ promotion_id: value.id }"
                    required
                    :manual="formType == 'CREATE'"
                    :error-messages="getErrorMessages('photo_url')"
                    @input="clearErrorMessages('photo_url')"
                    class="mx-auto"
                />
            </div>
        </v-col>
    </v-row>
</template>

<script>
import ImageInput from '@/components/inputs/ImageInput'
import FormMixin from '@/mixins/FormMixin'
import Promotion from '@/models/Promotion'

export default {
    name: 'AsidePromotionInput',
    mixins: [ FormMixin(Promotion, '') ],
    components: { ImageInput },
    props: {
        formType: {
            type: String,
            required: true,
        },
        previewMaxHeight: {
            type: String,
            default: '270px'
        },
        previewMaxWidth: {
            type: String,
            default: '200px',
        }
    },
    methods: {
        async triggerFileUploads() {
            await this.$refs.photo_field.requestFileUpload()
        },
    },
    computed: {
        isDetails() {
            return this.formType == 'DETAILS'
        },
    }
}
</script>

<style scoped lang="scss">
.image-preview {
    border-radius: 8px !important;
}
</style>
