<template>
    <v-row>
        <v-col cols="12" class="py-0">
            <cp-text-field 
                :placeholder="$t('forms.PromotionForm.sections.filters.activations_limit.placeholder')"
                :label="$t('forms.PromotionForm.sections.filters.activations_limit.label')"
                :hint="$t('forms.PromotionForm.sections.filters.activations_limit.hint')"
                v-model="activations_limit"
                type="number"
                :rules="rules"
                :error-messages="getErrorMessages(`filters[${index}].config.activations_limit`)"
                @input="clearErrorMessages(`filters[${index}].config.activations_limit`)"
                :disabled="isDetails"
            />
        </v-col>

        <v-col>
            <p class="secondary--text mb-0" v-html="computedDescription"></p>
        </v-col>
    </v-row>
</template>

<script>
import { gt, lte } from '@/utils/validation'
import _ from 'lodash'

export default {
    name: 'PromotionFilterActivationsLimit',
    props: {
        index             : {
            type      : Number,
            validation: (v) => _.isInteger(v)
        },
        value             : Object,
        getErrorMessages  : Function,
        clearErrorMessages: Function,
        formType          : {
            type: String,
            required: false,
            default: 'CREATE',
        },
    },
    data: vm => ({
        rules : [gt(0), lte(100)],
    }),
    computed: {
        activations_limit: {
            get() {
                return this.$lodash.get(this.value, 'config.max_activations')
            },
            set(v) {
                // Garante que esteja reativo
                this.$set(this.value.config, 'max_activations', v)
            },
        },
        computedDescription() {
            return this.$tc('forms.PromotionForm.sections.filters.activations_limit.hint_details', this.activations_limit)
        },
        isDetails() {
            return this.formType == 'DETAILS'
        },
    },
}
</script>