var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "12" } },
        [
          _c("cp-text-field", {
            attrs: {
              placeholder: _vm.$t(
                "forms.PromotionForm.sections.filters.activations_limit.placeholder"
              ),
              label: _vm.$t(
                "forms.PromotionForm.sections.filters.activations_limit.label"
              ),
              hint: _vm.$t(
                "forms.PromotionForm.sections.filters.activations_limit.hint"
              ),
              type: "number",
              rules: _vm.rules,
              "error-messages": _vm.getErrorMessages(
                "filters[" + _vm.index + "].config.activations_limit"
              ),
              disabled: _vm.isDetails
            },
            on: {
              input: function($event) {
                return _vm.clearErrorMessages(
                  "filters[" + _vm.index + "].config.activations_limit"
                )
              }
            },
            model: {
              value: _vm.activations_limit,
              callback: function($$v) {
                _vm.activations_limit = $$v
              },
              expression: "activations_limit"
            }
          })
        ],
        1
      ),
      _c("v-col", [
        _c("p", {
          staticClass: "secondary--text mb-0",
          domProps: { innerHTML: _vm._s(_vm.computedDescription) }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }