var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "12" } },
        [
          _c("cp-text-field", {
            attrs: {
              placeholder: _vm.$t(
                "forms.PromotionForm.sections.filters.new_register.placeholder"
              ),
              label: _vm.$t(
                "forms.PromotionForm.sections.filters.new_register.label"
              ),
              hint: _vm.$t(
                "forms.PromotionForm.sections.filters.new_register.hint"
              ),
              type: "number",
              rules: _vm.gtz,
              "error-messages": _vm.getErrorMessages(
                "filters[" + _vm.index + "].config.new_register"
              ),
              disabled: _vm.isDetails
            },
            on: {
              input: function($event) {
                return _vm.clearErrorMessages(
                  "filters[" + _vm.index + "].config.new_register"
                )
              }
            },
            model: {
              value: _vm.formatTimePast,
              callback: function($$v) {
                _vm.formatTimePast = $$v
              },
              expression: "formatTimePast"
            }
          })
        ],
        1
      ),
      _c("v-col", [
        _c("p", {
          staticClass: "secondary--text mb-0",
          domProps: {
            innerHTML: _vm._s(
              _vm.$tc(
                "forms.PromotionForm.sections.filters.new_register.hint_details",
                [_vm.formatTimePast]
              )
            )
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }