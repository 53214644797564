<template>
    <v-row>
        <v-col cols="12" class="py-0">
            <cp-text-field 
                :placeholder="$t('forms.PromotionForm.sections.actions.generate_points.value.placeholder')"
                :label="$t('forms.PromotionForm.sections.actions.generate_points.value.label')"
                :hint="$t('forms.PromotionForm.sections.actions.generate_points.value.hint')"
                type="number"
                :rules="gtz"
                v-model="value.config.products[0].value"
                :error-messages="getErrorMessages(`actions[0].config.products[0].value`)"
                @input="clearErrorMessages(`actions[0].config.products[0].value`)"
                :disabled="isDetails"
            />
        </v-col>

        <v-col cols="12" class="py-0">
            <cp-select-field
                :placeholder="$t('forms.PromotionForm.sections.actions.generate_points.type.placeholder')"
                :label="$t('forms.PromotionForm.sections.actions.generate_points.type.label')"
                :hint="$t('forms.PromotionForm.sections.actions.generate_points.type.hint')"
                :error-messages="getErrorMessages(`actions[0].config.products[0].type_id`)"
                hide-details
                :items="types"
                item-value="id"
                item-text="name"
                v-model="value.config.products[0].type_id"
                :loading="loadingData"
                @input="clearErrorMessages(`actions[0].config.products[0].type_id`)"
                :disabled="isDetails"
            />
        </v-col>

        <v-col>
            <p class="secondary--text mb-0">
                <span>{{ $t('forms.PromotionForm.sections.actions.generate_points.hint_details_1') }}</span>
                <span>{{ $tc('forms.PromotionForm.sections.actions.generate_points.hint_details_2', Number(getSimulateAmount)) }}</span>
                <span v-if="disableCashback">
                    {{ $t('forms.PromotionForm.sections.actions.generate_points.hint_details_3_disable_cashback') }}
                </span>
                <span v-else>
                    {{ $t('forms.PromotionForm.sections.actions.generate_points.hint_details_3', { value: getSimulateValue, type: getTypeName}) }}
                </span>
            </p>
        </v-col>
    </v-row>
</template>

<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import Type                 from '@/models/Type'
import CalculationRule      from '@/models/CalculationRule'
import { gt }               from '@/utils/validation'
import { roundNumber }      from '@/utils/mathUtils'
import { mapGetters }       from 'vuex'

export default {
    name: 'PromotionActionPointsGenerateSettings',
    mixins: [ HasErrorHandlerMixin ],
    props: {
        value             : Object,
        getErrorMessages  : Function,
        clearErrorMessages: Function,
        formType: {
            type: String,
            required: false,
            default: 'CREATE',
        },
    },
    data: vm => ({
        types           : [],
        calculationsRule: [],
        gtz             : [gt(0)],
        loadingData     : false,
        pagination      : {
            page    : 1,
            per_page: 200, 
        }
    }),
    async created() {
        await this.fetchTypes()
        await this.fetchRules()
    },
    methods: {
        async fetchTypes() {
            this.loadingData = true
            let res = await Type.list({ params: this.pagination })
                .catch(this.preErrorHandler)
            this.loadingData = false

            if (!res || !res.data || res.status != 200) return

            this.types = res.data
        },
        async fetchRules() {
            let res = await CalculationRule.list()
                .catch(this.preErrorHandler)
            this.loadingData = false

            if (!res || !res.data || res.status != 200) return

            this.calculationsRule = res.data
        },
        getType(type_id) {
            return this.types.find(type => type.id === type_id)
        },
        getCalculationRule() {
            const type_id = this.$lodash.get(this.value.config, 'products[0].type_id')
            return this.calculationsRule.find(rule => rule.type_id === type_id)
        },
        async preErrorHandler(e) {
            let errType
            const definedErrors = [
                'network_error'
            ]

            if (e.message == 'Network Error') {
                errType = 'network_error'
            }

            errType = definedErrors.includes(errType) ? errType : 'error'
            await new Promise((resolve) => {
                this.$bus.$emit('alert', this.$t(`forms.PromotionForm.alerts.${errType}`), 'error', resolve)
            })

            this.$emit('close-modal')
            this.errorHandler(e)
        },
    },
    computed: {
        ...mapGetters({
            disableCashback: 'company/disable_cashback',
        }),
        isDetails() {
            return this.formType == 'DETAILS'
        },
        getTypeName() {
            const id   = this.$lodash.get(this.value.config, 'products[0].type_id')
            const type = this.getType(id)

            return this.$lodash.get(type, 'name')
        },
        getSimulateValue() {
            const rule  = this.getCalculationRule()
            const value = this.$lodash.get(this.value.config, 'products[0].value')

            if (!rule || !value) return 0

            return roundNumber((Number(value * rule.in_ratio * rule.out_ratio)))
        },
        getSimulateAmount() {
            const rule = this.getCalculationRule()
            const value = this.$lodash.get(this.value.config, 'products[0].value')

            if (!rule || !value) return 0

            return roundNumber(Number(value * rule.in_ratio))
        }
    },
}
</script>