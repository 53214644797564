var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
        _vm.isDetails
          ? _c(
              "div",
              [
                _c("v-img", {
                  staticClass: "mx-auto pointer image-preview",
                  attrs: {
                    src: _vm.value.photo_url,
                    "max-height": _vm.$vuetify.breakpoint.mdAndUp
                      ? _vm.previewMaxHeight
                      : "",
                    "max-width": _vm.previewMaxWidth,
                    contain: ""
                  }
                })
              ],
              1
            )
          : _c(
              "div",
              [
                _c("ImageInput", {
                  ref: "photo_field",
                  staticClass: "mx-auto",
                  attrs: {
                    previewMaxHeight: _vm.previewMaxHeight,
                    previewMaxWidth: _vm.previewMaxWidth,
                    type: "update-promotion-photo",
                    params: { promotion_id: _vm.value.id },
                    required: "",
                    manual: _vm.formType == "CREATE",
                    "error-messages": _vm.getErrorMessages("photo_url")
                  },
                  on: {
                    input: function($event) {
                      return _vm.clearErrorMessages("photo_url")
                    }
                  },
                  model: {
                    value: _vm.value.photo_url,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "photo_url", $$v)
                    },
                    expression: "value.photo_url"
                  }
                })
              ],
              1
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }