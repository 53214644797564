var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "pr-md-5 py-0", attrs: { cols: "12" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
            _c("div", { staticClass: "primary--text" }, [
              _c("b", [
                _vm._v(
                  _vm._s(_vm.$t("forms.PromotionForm.sections.actions.title"))
                )
              ])
            ]),
            !_vm.isDetails
              ? _c("div", { staticClass: "secondary--text mb-2" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("forms.PromotionForm.sections.actions.description")
                    )
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      ),
      _vm.firstAction
        ? [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "py-0", attrs: { cols: "12" } },
                  [
                    _c("cp-select-field", {
                      ref: "select_type_action_input",
                      attrs: {
                        placeholder: _vm.$t(
                          "forms.PromotionForm.sections.actions.types.placeholder"
                        ),
                        label: _vm.$t(
                          "forms.PromotionForm.sections.actions.types.label"
                        ),
                        hint: _vm.$t(
                          "forms.PromotionForm.sections.actions.types.hint"
                        ),
                        "error-messages": _vm.getErrorMessages(
                          "type_action_select"
                        ),
                        items: _vm.availableActions,
                        "item-value": "type",
                        "item-text": "label",
                        clearable: true,
                        disabled: _vm.isDetails
                      },
                      on: {
                        input: function($event) {
                          return _vm.clearErrorMessages("type_action_select")
                        }
                      },
                      model: {
                        value: _vm.firstAction.type,
                        callback: function($$v) {
                          _vm.$set(_vm.firstAction, "type", $$v)
                        },
                        expression: "firstAction.type"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(_vm.firstAction.getSettingsComponent(), {
              tag: "component",
              attrs: {
                getErrorMessages: _vm.getErrorMessages,
                clearErrorMessages: _vm.clearErrorMessages,
                formType: _vm.formType
              },
              on: {
                "close-modal": function($event) {
                  return _vm.$emit("close-modal")
                }
              },
              model: {
                value: _vm.firstAction,
                callback: function($$v) {
                  _vm.firstAction = $$v
                },
                expression: "firstAction"
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }