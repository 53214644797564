<template>
    <BaseCrudTable
        ref="table"
        tableName="PromotionsTable"
        :headerConfigs="headerConfigs"
        :fetchFunction="fetchFunction"
        :pagination="pagination"
        v-bind="attrs"
        v-on="listeners"
    >
        <template #item.start_at="{ item }">
            {{ item.start_at | dateUTC }}
        </template>
        <template #item.end_at="{ item }">
            {{ item.end_at | dateUTC }}
        </template>
        <template #item.is_active="{ item }">
            <BooleanChip
                small label
                :value="item.is_active"
                :true-label="$t('globals.active')"
                :false-label="$t('globals.inactive')"
                label-class="white--text"
            />
        </template>
    </BaseCrudTable>
</template>

<script>
import BaseCrudTable from '@/components/tables/BaseCrudTable'
import BooleanChip   from '@/components/chips/BooleanChip'

export default {
    name: 'PromotionsTable',
    inheritAttrs: false,
    props: {
        fetchFunction: {
            type: Function,
            required: true,
        },
    },
    components: { BaseCrudTable, BooleanChip },
    data: () => ({
        pagination: {
            page    : 1,
            per_page: 5,
        },
        headerConfigs: [
            {
                value: 'name',
                showOnMobile: true,
            },
            {
                value: 'start_at',
                showOnMobile: true,
            },
            {
                value: 'end_at',
                showOnMobile: true,
            },
            {
                value: 'is_active',
                showOnMobile: true,
            },
        ]
    }),
    created() {
        this.pagination.page = Number(this.$route.query.page) || this.pagination.page || 1
    },
    computed: {
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { ...listeners } = this.$listeners
            return listeners
        },

        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
    methods: {
        async refresh() {
            await this.$refs.table.preFetchItems()
        },
    },
}
</script>